<template>
	<v-container fluid>
		<v-row v-if="plantedTrees">
			<v-col
				v-for="(metric, index) in plantedTrees.subcount"
				cols="4"
				:key="index"
			>
				<KeyMetrics
					:title="index"
					:value="metric"
					:is-editable="false"
					color="blue lighten-3"
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col
				xs="12"
				sm="12"
				md="12"
				xl="4"
			>
				<v-card>
					<v-card-text>
						<Highchart :options="chart" :updateArgs="[true, true]" />
						<div v-if="chart.loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
							<Loader :show-text="false" />
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-card>
					<v-data-table
						:loading="detailed.loading"
						:headers="detailed.headers"
						:items="detailed.items"
						:options.sync="detailed.options"
						:server-items-length="detailed.totalItems"
						:footer-props="{'items-per-page-options':[10,25,50]}"
					>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'
	import KeyMetrics from '@/components/material/KeyMetrics'
	import Highchart from '@/components/material/Highchart'
	import Loader from "@/components/core/Loader"

	export default {
		components: {
			KeyMetrics,
			Loader,
			Highchart,
		},
		data () {
			return {
				plantedTrees: {},
				detailed: {
					headers: [
						{ text: `Profile`, value: `display_name` },
						{ text: `Planted trees`, value: `count` },
					],
					items: [],
					totalItems: 0,
					options: {},
					loading: false,
				},
				chart: {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily planted trees`
					},
					series: []
				},
			}
		},
		methods: {
			refetch_all_data () {
				this.$httpInt.get(`/statistics/list-planted-trees/?timerange=allHistory`)
					.then(res => this.plantedTrees = { count: res.data.count, subcount: res.data.subcount })

				this.getDailyPlantedTrees()
			},
			getDailyPlantedTrees() {
				this.chart = {
					loading: true,
					yAxis: [{
						title: {
							enabled: false,
						},
					}],
					title: {
						text: `Daily planted trees`
					},
					series: []
				}

				this.$httpInt.get(`/statistics/daily-planted-trees/?aggregate=true`)
					.then(res => {
						this.chart.series = [{
							data: res.data.data[0],
							name: `Per day`,
						}]
					})
					.finally(() => this.chart.loading = false)
			},
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
		},
		mixins: [globalMixIn],
		mounted() {
			this.refetch_all_data()
		},
		name: `StatisticsPlantedTrees`,
		watch: {
			'detailed.options': {
				handler () {
					this.detailed.loading = true
					this.$httpInt.get(`/statistics/detailed-planted-trees-by-profiles/?timerange=allHistory`, {
						params: this.detailed.options
					})
						.then(res => {
							this.detailed.items = res.data.data
							this.detailed.totalItems = res.data.pagination.total_items
						})
						.finally(() => this.detailed.loading = false)
				},
				deep: true,
			},
		},
	}

</script>
