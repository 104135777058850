<template>
	<v-hover v-slot="{ hover }">
		<v-card class="pa-4 text-center">
			<v-container>
				<template v-if="!editing">

					<v-tooltip
						top
						v-if="forecast"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								absolute
								top
								left
								v-bind="attrs"
								v-on="on"
							>
								<svg-icon icon="forecast-chart" />
							</v-btn>
						</template>
						<span>
							<div class="font-weight-bold">Current: {{ localeInteger(forecast.count) }}</div>
							<div>Forecast: {{ localeInteger(forecast.forecast) }}</div>
							<div v-if="goal">Goal: {{ localeInteger(goal) }} ({{ Math.round(forecast.forecast/goal*100) }}%)</div>
							<div class="pt-2">Reliable: {{ Math.round(forecast.reliability) }}%</div>
						</span>
					</v-tooltip>
					<v-btn
						v-if="hover && isEditable && dateRangeName !== `customRange`"
						icon
						absolute
						top
						right
						@click="edit"
					>
						<svg-icon icon="pencil" />
					</v-btn>

					<svg-icon v-if="false" :icon="icon" />
					<v-progress-circular
						rotate="270"
						size="170"
						width="15"
						:value="calcValue"
						:color="calcColor"
					>
						<div class="flex-column">
							<div :class="calcValueSize">{{ showValue(value) }}</div>
							<template v-if="goal">
								<v-divider />
								<div class="subtitle-2">{{ localeInteger(goal) }}</div>
							</template>
						</div>
					</v-progress-circular>
					<div class="text-h6">
						{{ title }}
					</div>
					<div class="text-body-1 grey--text text--darken-1">
						{{ subTitle || `&#8205;` }}
					</div>
				</template>
				<template v-if="editing">
					<v-btn
						icon
						absolute
						top
						right
						@click="save"
					>
						<svg-icon icon="check-circle" class="green--text" />
					</v-btn>
					<v-btn
						icon
						absolute
						top
						left
						@click="cancel"
					>
						<svg-icon icon="cross-circle" class="red--text" />
					</v-btn>

					<div class="text-h6 pt-16 pb-1">
						New goal
					</div>

					<v-text-field
						placeholder=" "
						v-model="newGoal"
					></v-text-field>

					<div class="text-h6">
						{{ title }}
					</div>
					<div class="text-body-1 grey--text text--darken-1">
						{{ subTitle || `&#8205;` }}
					</div>
				</template>
			</v-container>
		</v-card>
	</v-hover>
</template>

<script>
import { globalMixIn } from '@/App'

export default {
	computed: {
		calcValueSize() {
			if (this.value < 10000) return `text-h3`
			if (this.value < 100000) return `text-h4`
			return `text-h5`
		},
		calcValue() {
			const value = (typeof this.value === 'string' || this.value instanceof String) ? parseInt(this.value) : this.value
			const goal = (typeof this.goal === 'string' || this.goal instanceof String) ? parseInt(this.goal) : this.goal
			return Math.round(value / goal * 100)
		},
		calcColor() {
			if (!this.value && this.value !== 0) return `grey lighten-2`

			if (this.color) return this.color
			if (!this.goal) return `blue lighten-3`

			const preValue = this.forecast && this.forecast.forecast || this.value
			const value = (typeof preValue === 'string' || preValue instanceof String) ? parseInt(preValue) : preValue
			const goal = (typeof this.goal === 'string' || this.goal instanceof String) ? parseInt(this.goal) : this.goal
			const calcValue = Math.round(value / goal * 100)

			if (calcValue < 60) return `red`
			if (calcValue < 80) return `orange`
			return `green`
		},
	},
	data () {
		return {
			editing: false,
			newGoal: null,
		}
	},
	methods: {
		showValue(number) {
			if (number === 0) return `0`
			if (!number) return `-`
			return this.localeInteger(number)
		},
		save() {
			this.$httpInt.patch(`/statistics/goals/`, {
				key: this.metricName,
				daterange_name: this.dateRangeName,
				value: this.newGoal,
			})
				.then(() => {
					this.editing = false
					this.$emit('changed', null)
				})
		},
		cancel() {
			this.editing = false
		},
		edit() {
			this.newGoal = this.goal
			this.editing = true
		},
	},
	mixins: [globalMixIn],
	name: `MaterialKeyMetrics`,
	props: {
		icon: {
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		subTitle: {
			type: String,
			default: ``,
		},
		title: {
			type: String,
			default: ``,
		},
		color: {
			type: String,
			default: ``,
		},
		value: {
			type: [Number, String],
			default: 0,
		},
		goal: {
			type: [Number, String],
			default: 0,
		},
		dateRangeName: {
			type: String,
			default: ``,
		},
		metricName: {
			type: String,
			default: ``,
		},
		forecast: {
			type: Object,
		},
	}
}
</script>